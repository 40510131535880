<template>
  <div
    class="Conditions"
  >
    <h2>BASES LEGALES DEL CONCURSO</h2>
    <p>
      A continuación, se recogen las Bases del Concurso “FIN DE SEMANA HOTEL 4
      ESTRELLAS” (en adelante, Concurso) llevado a cabo por el Grupo de Investigación
      DigiMk.com (https://www.neuromarketinguv.com/) (en adelante, los Organizadores del
      Concurso). El Concurso se regirá con arreglo a las presentes bases legales (en adelante,
      las Bases).
    </p>

    <h2>1. ÁMBITO TEMPORAL</h2>
    <p>
      El Concurso tendrá vigencia desde las 09:00 horas del día 23 de noviembre de 2021
      hasta las 21:00 horas del día 31 de enero de 2022.
      El día de 31 de enero de 2022 se seleccionará y comunicará el/la ganador/a. El premio
      se podrá disfrutar durante 2022 en las fechas elegidas por el ganador siempre y cuando
      el hotel tenga disponibilidad.
      Si por causas de fuerza mayor o ajenas al control los Organizadores del Concurso fuera
      preciso aplazar o modificar la duración del Concurso, o anular o repetir el mismo, este
      hecho se notificaría a los/as participantes por el mismo medio por el cual se publican
      estas Bases.
    </p>

    <h2>2. ÁMBITO TERRITORIAL</h2>
    <p>
      La Promoción es válida a nivel nacional. No se considerarán válidas las participaciones
      que no se ajusten adecuadamente a los requisitos de participación según se indica en el
      apartado 3. REQUISITOS PARA PARTICIPAR.
    </p>

    <h2>3. REQUISITOS PARA PARTICIPAR</h2>
    <p>
      La participación estará limitada a todas las personas físicas, mayores de edad durante el
      periodo del Concurso que hayan participado en este estudio.
      La participación es gratuita, voluntaria y está limitada a quienes cumplan todos los
      requisitos que se han detallado.
    </p>

    <h2>
      4. COMPROBACIÓN DE REQUISITOS Y DECLARACIÓN DE GANADOR/A
    </h2>

    <p>
      Una vez cerrado el plazo de participación, los Organizadores del Concurso comprobarán
      que quien hayan resultado elegido/a en el mismo cumplen con todos los requisitos
      establecidos en las presentes Bases y que puede ser declarado ganador/a.

      Para ello, los Organizadores del Concurso contactarán con la persona afortunada vía e-
      mail y se le informará del resultado del mismo. La persona ganadora deberá responder
      indicando sus datos de contacto, en un plazo máximo de 72 horas desde la realización
      del contacto por parte de los Organizadores del Concurso, y si acepta o renuncia al
      premio.
      Una vez recibida la aceptación expresa se procederá a enviar el premio a la persona
      ganadora.
      En el caso de renuncia, el ganador perderá la posibilidad de disfrutar del premio.
      Si alguna de las personas elegidas renuncia al premio o no contesta en el plazo de 72
      horas, los Organizadores del Concurso se comunicarán con la primera persona suplente
      (o en su caso los sucesivos suplentes hasta un máximo de 4) a los efectos de proponer y
      en su caso (si no renuncia a su vez) seguir los pasos para la declaración de ganador/a
      que se han indicado. Alcanzado el número máximo de personas suplentes, siendo que
      varias de ellas o todas hayan renunciado o que no cumplan los requisitos establecidos en
      las presentes Bases, los Organizadores del Concurso podrán declarar desierto el
      Concurso.
    </p>

    <h2>5. PREMIO</h2>
    <p>
      Se establece un único premio de una estancia para dos personas, con desayuno incluido,
      en un hotel de 4 estrellas en la ciudad elegida durante la participación en el estudio
      (Benidorm o Granada).
    </p>

    <h2>6. MODIFICACIONES Y/O ANEXOS</h2>
    <p>
      Los Organizadores del Concurso se reservan el derecho a realizar modificaciones o
      añadir anexos sobre las presentes Bases en relación con su mecánica y premio, siempre
      que las mismas estén justificadas o no perjudiquen a las personas participantes, se
      realicen dentro del periodo de vigencia del Concurso, y se comuniquen debidamente.
    </p>

    <h2>
      7. DATOS PERSONALES
      PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL:
    </h2>
    <p>
      En cumplimiento de lo dispuesto en la Ley Orgánica 3/2018 de 5 de diciembre, de
      Protección de Datos personales y garantía de los derechos digitales, los Organizadores
      del Concurso comunican que los datos personales de las personas participantes se
      incorporarán a los ficheros de su titularidad con la finalidad de gestionar su
      participación en todas las fases del Concurso, así como para la entrega del premio.
      El/La participante autoriza a los Organizadores del Concurso a que utilice los datos de
      carácter personal necesarios para su identificación que se recaben de su persona durante
      su participación en el Concurso. Si los datos de carácter personal de una persona

      ganadora del Concurso fueran inciertos, falsos, incompletos o no fueran actualizados de
      manera que la identificación de la persona ganadora sea imposible por medios
      razonables, los Organizadores del Concurso se reservan el derecho a descalificarlo del
      Concurso, quedando libre de toda responsabilidad al respecto.
      La persona podrá ejercer los derechos de acceso, supresión, rectificación, oposición,
      limitación y portabilidad, así como la revocación en cualquier momento de los
      consentimientos prestados, mediante email a los Organizadores del Concurso:
      promocionesplusexperience@gmail.com
    </p>

    <h2>8. ACEPTACIÓN DE LAS BASES</h2>
    <p>
      La participación en este Concurso supone la aceptación íntegra de las presentes Bases y
      la sumisión expresa a las decisiones interpretativas que de las mismas efectúen los
      Organizadores del Concurso.
      Los Organizadores del Concurso se reservan el derecho a descalificar a los/as
      participantes que incumplan las mismas y, especialmente, cualquier participación que
      vaya en contra de estas Bases.
      Con la aceptación de estas Bases los/as participantes comprenden los términos y
      condiciones de las mismas y se comprometen a cumplir con la normativa vigente que
      pueda resultar de aplicación a este respecto, de modo que por el simple hecho de
      participar en el Concurso están prestando su consentimiento a someterse a las Bases y a
      la legalidad de forma total e irrevocable.
      La manifestación en contrario de la persona participante, independientemente de en qué
      momento se realice, implicará su autoexclusión del Concurso y la liberación de los
      Organizadores del Concurso de cualquier compromiso adquirido con el/la participante.
    </p>

    <h2>9. RESERVAS Y LIMITACIONES</h2>
    <p>
      Los Organizadores del Concurso quedan eximidos de cualquier responsabilidad en el
      supuesto de existir algún error en los datos facilitados por las propias personas
      participantes que impidiera su identificación.
      Los Organizadores del Concurso se reservan el derecho de declarar la Promoción
      desierta en cualquier momento si ninguno de los ganadores reuniese los requisitos
      necesarios para participar en el Concurso. En dicho supuesto, los Organizadores del
      Concurso comunicará este extremo a los ganadores, sin que tengan derecho a hacer
      reclamación alguna por ello.
      Asimismo, en caso de que el desarrollo del Concurso, se vea afectado por circunstancias
      que se encuentren más allá del control de los Organizadores del Concurso, o no puedan
      llevarse a cabo a causa del incumplimiento presente, previsible o supuesto de cualquier
      Ley o norma aplicable, los Organizadores del Concurso podrán cancelar todo o
      cualquier parte del Concurso sin que las personas participantes puedan hacer
      reclamación alguna por ello.
    </p>

    <p>
      Los Organizadores del Concurso se reservan el derecho de eliminar justificadamente a
      cualquier participante que trate de, o llegue a defraudar, alterar o inutilizar el buen
      funcionamiento y el transcurso normal y reglamentario del presente Concurso, así como
      la resolución de cualquier cuestión derivada de la presente actividad promocional.
      Los Organizadores del Concurso quedan excluidos de cualquier responsabilidad por
      daños y perjuicios de toda naturaleza que puedan deberse a la falta temporal de
      disponibilidad o de continuidad del funcionamiento de los servicios mediante los que se
      participa en el Concurso, a la defraudación de la utilidad que las personas usuarios
      hubieren podido atribuir a los mismos, y en particular, aunque no de modo exclusivo, a
      los fallos en el acceso a las distintas páginas y envíos de respuestas de participación a
      través de Internet, o comunicaciones vía email habilitadas al efecto.
      Los Organizadores del Concurso se reservan el derecho a efectuar cambios que
      redunden en el buen fin del Concurso cuando concurra causa justa o motivos de fuerza
      mayor que impidan llevarla a término en la forma en que recogen las presentes bases.
      Los Organizadores del Concurso se reservan el derecho a aplazar o ampliar el período
      del Concurso, así como la facultad de interpretar las presentes bases legales.
      Los Organizadores del Concurso se reservan el derecho de acortar, prorrogar, modificar
      o cancelar este Concurso, si concurrieran circunstancias excepcionales que impidieran
      su realización, comunicando dichas circunstancias de manera que se evite cualquier
      perjuicio para las personas candidatas en el Concurso. Los Organizadores del Concurso
      no serán responsable de los retrasos, pérdidas o deterioros por causas que no le sean
      imputables.
      Los Organizadores del Concurso tampoco responderán de los casos de fuerza mayor que
      pudieran impedir a la persona ganadora el disfrute total o parcial de su premio.
      Asimismo, los Organizadores del Concurso quedarán exentos de toda responsabilidad si
      ocurriere alguno de los casos señalados, así como de cualquier responsabilidad por los
      daños y perjuicios que pudiesen ocasionarse durante el disfrute del premio.
    </p>

    <h2>10. NULIDAD</h2>
    <p>
      Si cualquier previsión de las presentes Bases fuese declarada, total o parcialmente, nula
      o ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o parte de la
      misma que resulte nula o ineficaz, subsistiendo en todo lo demás el resto de Bases y
      teniéndose tal disposición o la parte de la misma que resulte afectada por no puesta,
      salvo que, por resultar esencial a las presentes Bases, hubiese de afectarlas de forma
      integral.
    </p>

    <h2>11. JURISDICCIÓN Y LEY APLICABLE </h2>
    <p>
      Las presentes Bases se interpretarán y regirán de conformidad con la legislación
      española. Para cualquier cuestión litigiosa derivada de la existencia, acceso, utilización
      o contenido de las Bases, a persona participante y los Organizadores del Concurso
      renuncian expresamente a cualquier otro fuero que pudiera corresponderles,
      sometiéndose a la jurisdicción y competencia exclusiva de los Juzgados y Tribunales de
      Valencia.
    </p>
  </div>
</template>

<script>

export default {

};
</script>

<style lang="scss" scoped>
.Conditions{
  margin: 3rem;
  padding: 2rem 4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #03a9f42b;

  .check-button{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p{
    font-size:1rem;
  }
}
</style>
