var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cities && _vm.texts
    ? _c(
        "div",
        { staticClass: "HomeView" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.texts.data[0].primeTitle))]),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.texts.data[0].primeFistText) + " ")
          ]),
          _vm._m(0),
          _c("div", [
            _c(
              "ul",
              _vm._l(_vm.cities.data, function(item, index) {
                return _c("li", { key: index }, [
                  _c("strong", [
                    _c(
                      "a",
                      {
                        staticClass: "username",
                        on: {
                          click: function($event) {
                            return _vm.setLocation(item.id)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  ])
                ])
              }),
              0
            )
          ]),
          _vm.ended
            ? _c("div", [
                _c("p", [_vm._v(" Ya has participado en el sorteo ")])
              ])
            : _c("div", { staticClass: "bottom-block" }, [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.texts.data[0].primeSecondText) + " ")
                ]),
                !_vm.selectedDestiny
                  ? _c("p", [
                      _vm._v(
                        " No se ha elegido ningún destino aún. Pulsa sobre un destino para comenzar. "
                      )
                    ])
                  : _c("p", [
                      _vm._v(" El destino elegido es "),
                      _c("strong", [_vm._v(_vm._s(_vm.cityName))])
                    ])
              ]),
          _vm.selectedDestiny && !_vm.ended
            ? _c(
                "BaseButton",
                { attrs: { size: "l" }, on: { click: _vm.startExp } },
                [_vm._v(" Seleccionar hotel ")]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Elija su destino")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }