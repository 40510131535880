<template class="Trivago">
  <body
    v-if="roomInfo"
    style="touch-action: manipulation; -ms-touch-action: manipulation;"
    class="nsi boomerang web53018 web53513 web54999 web51591 web52366 web51251 web52891 web53005 web53172 web54858 web57155 web53687 web52590 web53393 web53894 web54061 web53028 web56467-1 web56861-3 web55145 web57488 web55136 web58205 web57889-2 in-hotel-results-view"
    itemscope="itemscope"
    itemtype="https://schema.org/WebPage"
    data-co_pa_co="1"
  >
    <div
      id="page_wrapper"
      class="page_wrapper sticky__scroll-container js-page-wrapper"
    >
      <div class="compass js-compass">
        <header
          id="js_navigation"
          class="siteheader fs-header"
          role="banner"
          itemprop="hasPart"
          itemscope=""
          itemtype="http://schema.org/WPHeader"
        >
          <a
            key="header-logo"
            href="https://www.trivago.es/"
            class="siteheader__logo-link js-logo siteheader__logo-link--new-user"
          >
            <svg
              version="1.1"
              width="102"
              height="32"
              viewBox="0 0 102 32"
              class="siteheader__logo"
              aria-labelledby="header-logo-id"
              role="img"
              focusable="false"
              tabindex="-1"
            >
              <title id="header-logo-id">
                trivago
              </title>
              <path
                class="logo-svg-path"
                d="M33.706,6.989h5.441l4.133,11.149h0.066l3.83-11.149h5.071L45.8,23.377h-5.34"
                fill="#FC9E15"
              />
              <path
                class="logo-svg-path"
                d="M13.971,11.018H9V16.5c0,0.93.082,2.119,0.959,2.655a4.649,4.649,0,0,0,2.9.283,2.242,2.242,0,0,0,1.107-.386v4.2a10.114,10.114,0,0,1-1.938.4c-3.556.359-8.54-.253-8.54-4.838v-7.8H0V6.988H3.492V2.153H9V6.989h4.97v4.03Zm14.1-4.03h4.97V23.377h-4.97V6.989Zm-0.2-4.311a2.676,2.676,0,1,1,5.351,0,2.677,2.677,0,0,1-5.353,0"
                fill="#1391D2"
              />
              <path
                class="logo-svg-path"
                d="M90.124,15.195c0,2.215,1.289,4.13,3.648,4.13s3.647-1.915,3.647-4.131-1.287-4.133-3.647-4.133-3.647,1.917-3.648,4.134m-4.556,0a8.224,8.224,0,0,1,14.711-5.43,9.491,9.491,0,0,1,.687,9.766,8.214,8.214,0,0,1-15.4-4.336"
                fill="#E3403D"
              />
              <path
                class="logo-svg-path"
                d="M80.24,6.973v2.2H80.164a3.041,3.041,0,0,0-.574-0.613c-3.254-2.8-8.812-2.238-10.915,1.679a11.506,11.506,0,0,0-.924,6.812c0.283,2.077,1.05,4.275,2.879,5.478a7.739,7.739,0,0,0,6.936.71,4.8,4.8,0,0,0,2.075-1.572H79.7v1.588a3.9,3.9,0,0,1-.984,2.881c-1.449,1.493-4.218,1.4-6.079.894a9.223,9.223,0,0,1-2.914-1.307l-2.451,3.6a11.307,11.307,0,0,0,3.906,1.9c3.583,0.949,8.543.928,11.312-1.921,1.561-1.606,2.2-3.96,2.2-7.06V6.971H80.242ZM78.984,18.2a3.707,3.707,0,0,1-5.4,0,5.013,5.013,0,0,1,0-6,3.707,3.707,0,0,1,5.4,0,5.011,5.011,0,0,1,0,6c-0.639.763,0.641-.763,0,0v0Z"
                fill="#E3403D"
              />
              <path
                class="logo-svg-path"
                d="M15.314,6.989h5.105V9.675h0.051A5.715,5.715,0,0,1,22.384,7.4a6.338,6.338,0,0,1,4.482-.6v4.6c-1.029-.278-3.2-0.715-4.717.211-0.96.586-1.731,1.288-1.731,4.929v6.839H15.313V6.989h0Z"
                fill="#1391D2"
              />
              <path
                class="logo-svg-path"
                d="M56.992,16.844c-1,.62-1.683,1.829-0.763,2.775,0.9,0.922,2.522.829,3.632,0.441a2.98,2.98,0,0,0,2.2-2.784V16.258c0.017,0-3.794-.208-5.069.585h0Zm5.07,4.518H61.969a5.079,5.079,0,0,1-2.232,1.852c-3.563,1.464-8.748-.019-8.75-4.492,0-4.655,5.07-5.606,8.784-5.712,0.817-.021,1.572-0.034,2.267-0.034a2.62,2.62,0,0,0-.957-2.132,4.243,4.243,0,0,0-4.535-.268A6.2,6.2,0,0,0,54.716,12L52.028,9.248A9.255,9.255,0,0,1,55.32,7.282a11.844,11.844,0,0,1,7.044-.27,5.625,5.625,0,0,1,3.952,4.437,17.479,17.479,0,0,1,.314,3.61v8.317H62.063V21.362h0Z"
                fill="#FC9E15"
              />
            </svg>
          </a>
          <nav class="alternative-products-nav alternative-products-nav--header">
            <a
              href="https://www.trivago.es/"
              class="alternative-products-nav__item js_log alternative-products-nav__item--active"
              data-log="100:2636:1"
            >
              <span class="icon-ic alternative-products-nav__icon icon-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  focusable="false"
                  tabindex="-1"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      class="svg-color--primary"
                      d="M-2-5h24v24H-2z"
                    />
                    <path
                      class="svg-color--secondary"
                      fill="#37454D"
                      d="M19 12c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1V8h18v4zM2.09 6.41c.14-.54.47-.98.91-1.21.23-.13.48-.2.75-.2h3.5c.64 0 1.21.4 1.51 1 .15.29.24.64.24 1H2c0-.21.03-.41.09-.59zM3 1h14v3.12c-.24-.08-.49-.12-.75-.12h-3.5c-1.2 0-2.21.84-2.59 2-.1.31-.16.65-.16 1 0-.35-.06-.69-.16-1-.38-1.16-1.39-2-2.59-2h-3.5c-.26 0-.51.04-.75.12V1zm8.24 5c.3-.6.87-1 1.51-1h3.5c.27 0 .52.07.75.2.44.23.77.67.91 1.21.06.18.09.38.09.59h-7c0-.36.09-.71.24-1zM19 7c0-.93-.39-1.76-1-2.31V1c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v3.69C1.39 5.24 1 6.07 1 7c-.55 0-1 .45-1 1v4c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-.55-.45-1-1-1z"
                    />
                  </g>
                </svg>
              </span>

              <span class="alternative-products-nav__label">
                Alojamientos
              </span>
            </a>

            <div
              id="weekend-header-link"
              data-log="100:2636:5"
              class="alternative-products-nav__item js_log"
              href="#"
              rel="nofollow noopener"
            >
              <span class="icon-ic alternative-products-nav__icon icon-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="currentColor">
                    <path d="M12 5a4 4 0 104 4 4 4 0 00-4-4zm0 7a3 3 0 113-3 3 3 0 01-3 3z" />
                    <path
                      d="M18 8.23a6 6 0 00-11.9 0A6.49 6.49 0 006 9a5.79 5.79 0 00.38 2.09L7 12.28l4.15 8.21a1 1 0 001.74 0L17 12.28l.6-1.19A5.79 5.79 0 0018 9a6.49 6.49 0 000-.77zM12 20l-4.7-9.31A4.77 4.77 0 017 9a4.88 4.88 0 010-.64 5 5 0 019.92 0A5.14 5.14 0 0117 9a4.77 4.77 0 01-.3 1.69z"
                    />
                  </g>
                </svg>
              </span>

              <span class="alternative-products-nav__label">
                Weekend
              </span>

              <span class="pill-new">
                Nuevo
              </span>
            </div>
          </nav>

          <div class="siteheader__headertools js_header_tools siteheader__menu">
            <div class="pos-relative has-popover">
              <button
                type="button"
                class="siteheader__control"
                data-qa="header-login"
              >
                <span class="flex-wrapper">
                  Iniciar sesión
                </span>
              </button>
            </div>
          </div>
        </header>
      </div>

      <main
        class="js-main main-wrap"
        role="main"
      >
        <div class="centerwrapper centerwrapper--item clearfix">
          <div
            id="main_content"
            class="page clearfix js-main-content"
          >
            <div class="main">
              <div
                id="js_center_content"
                class="centercol content"
              >
                <div
                  id="content"
                  class="content"
                >
                  <div
                    id="js_item_list_container"
                    class="container_itemlist"
                  >
                    <div
                      id="js-main-controller"
                      class="main-controller__main--b4a2e main-controller__layout--46d74"
                    >
                      <div class="">
                        <section
                          id="js_item_list_section"
                          data-qa="itemlist"
                          class="item-list clearfix"
                        >
                          <ol
                            id="js_itemlist"
                            class="hotel-list item-order itemlist hotellist clearfix"
                            data-co_mode="1"
                          >
                            <li
                              id="7452"
                              data-id-sponsored="7452_false"
                              class="hotel-item item-order__list-item js_co_item"
                            >
                              <div>
                                <article
                                  data-qa="itemlist-element"
                                  itemscope="itemscope"
                                  data-item="7452"
                                  class="item bg-white"
                                >
                                  <div class="pos-relative item__wrapper">
                                    <div class="item__image-area">
                                      <div
                                        data-qa="gallery-slideout"
                                        class="item__image-wrapper pos-relative"
                                      >
                                        <div
                                          class="lazy-image__image-wrapper"
                                          itemprop="image"
                                          itemscope="itemscope"
                                        >
                                          <img
                                            alt=""
                                            itemprop="url"
                                            :src="loadImageServer(roomImage)"
                                            class="lazy-image__image item__image item__image--has-gallery"
                                            decoding="async"
                                            draggable="false"
                                          >
                                        </div>
                                      </div>
                                    </div>

                                    <div class="item__flex-column">
                                      <div class="item__details">
                                        <div class="item__name item__name--link">
                                          <h3
                                            class="m-0"
                                            itemprop="name"
                                          >
                                            <span
                                              class="item-link name__copytext"
                                              title="Ayre Hotel Sevilla"
                                              dir="ltr"
                                              data-qa="item-name"
                                            >
                                              {{ roomTitle }}
                                            </span>
                                          </h3>

                                          <div class="quick-info">
                                            <div
                                              class="stars-wrp"
                                              itemprop="starRating"
                                              itemscope="true"
                                            >
                                              <span
                                                v-if="pricipalRating >= '5'"
                                                class="icon-ic star"
                                              >
                                                <svg
                                                  focusable="false"
                                                  tabindex="-1"
                                                  width="12"
                                                  height="12"
                                                  viewBox="0 0 12 12"
                                                >
                                                  <path
                                                    class="svg-color--primary"
                                                    fill="#F6AB3F"
                                                    d="M11.988 5.21a.667.667 0 00-.545-.534l-3.604-.6L6.63.455a.666.666 0 00-1.262.001L4.16 4.076l-3.603.6a.667.667 0 00-.233 1.228L3.2 7.63l-1.165 3.493a.67.67 0 00.25.758.672.672 0 00.798-.026L6 9.52l2.917 2.333a.66.66 0 00.796.027.665.665 0 00.252-.758L8.8 7.63l2.876-1.725a.667.667 0 00.312-.696z"
                                                  />
                                                </svg>

                                                <svg
                                                  focusable="false"
                                                  tabindex="-1"
                                                  width="12"
                                                  height="12"
                                                  viewBox="0 0 12 12"
                                                >
                                                  <path
                                                    class="svg-color--primary"
                                                    fill="#F6AB3F"
                                                    d="M11.988 5.21a.667.667 0 00-.545-.534l-3.604-.6L6.63.455a.666.666 0 00-1.262.001L4.16 4.076l-3.603.6a.667.667 0 00-.233 1.228L3.2 7.63l-1.165 3.493a.67.67 0 00.25.758.672.672 0 00.798-.026L6 9.52l2.917 2.333a.66.66 0 00.796.027.665.665 0 00.252-.758L8.8 7.63l2.876-1.725a.667.667 0 00.312-.696z"
                                                  />
                                                </svg>

                                                <svg
                                                  focusable="false"
                                                  tabindex="-1"
                                                  width="12"
                                                  height="12"
                                                  viewBox="0 0 12 12"
                                                >
                                                  <path
                                                    class="svg-color--primary"
                                                    fill="#F6AB3F"
                                                    d="M11.988 5.21a.667.667 0 00-.545-.534l-3.604-.6L6.63.455a.666.666 0 00-1.262.001L4.16 4.076l-3.603.6a.667.667 0 00-.233 1.228L3.2 7.63l-1.165 3.493a.67.67 0 00.25.758.672.672 0 00.798-.026L6 9.52l2.917 2.333a.66.66 0 00.796.027.665.665 0 00.252-.758L8.8 7.63l2.876-1.725a.667.667 0 00.312-.696z"
                                                  />
                                                </svg>

                                                <svg
                                                  focusable="false"
                                                  tabindex="-1"
                                                  width="12"
                                                  height="12"
                                                  viewBox="0 0 12 12"
                                                >
                                                  <path
                                                    class="svg-color--primary"
                                                    fill="#F6AB3F"
                                                    d="M11.988 5.21a.667.667 0 00-.545-.534l-3.604-.6L6.63.455a.666.666 0 00-1.262.001L4.16 4.076l-3.603.6a.667.667 0 00-.233 1.228L3.2 7.63l-1.165 3.493a.67.67 0 00.25.758.672.672 0 00.798-.026L6 9.52l2.917 2.333a.66.66 0 00.796.027.665.665 0 00.252-.758L8.8 7.63l2.876-1.725a.667.667 0 00.312-.696z"
                                                  />
                                                </svg>
                                              </span>

                                              <span
                                                v-else
                                                class="icon-ic star"
                                              >


                                                <svg
                                                  focusable="false"
                                                  tabindex="-1"
                                                  width="12"
                                                  height="12"
                                                  viewBox="0 0 12 12"
                                                >
                                                  <path
                                                    class="svg-color--primary"
                                                    fill="#F6AB3F"
                                                    d="M11.988 5.21a.667.667 0 00-.545-.534l-3.604-.6L6.63.455a.666.666 0 00-1.262.001L4.16 4.076l-3.603.6a.667.667 0 00-.233 1.228L3.2 7.63l-1.165 3.493a.67.67 0 00.25.758.672.672 0 00.798-.026L6 9.52l2.917 2.333a.66.66 0 00.796.027.665.665 0 00.252-.758L8.8 7.63l2.876-1.725a.667.667 0 00.312-.696z"
                                                  />
                                                </svg>

                                                <svg
                                                  focusable="false"
                                                  tabindex="-1"
                                                  width="12"
                                                  height="12"
                                                  viewBox="0 0 12 12"
                                                >
                                                  <path
                                                    class="svg-color--primary"
                                                    fill="#F6AB3F"
                                                    d="M11.988 5.21a.667.667 0 00-.545-.534l-3.604-.6L6.63.455a.666.666 0 00-1.262.001L4.16 4.076l-3.603.6a.667.667 0 00-.233 1.228L3.2 7.63l-1.165 3.493a.67.67 0 00.25.758.672.672 0 00.798-.026L6 9.52l2.917 2.333a.66.66 0 00.796.027.665.665 0 00.252-.758L8.8 7.63l2.876-1.725a.667.667 0 00.312-.696z"
                                                  />
                                                </svg>
                                              </span>
                                            </div>

                                            <p
                                              data-qa="accommodation-type"
                                              class="accommodation-type"
                                            >
                                              {{ roomAddress }}
                                            </p>
                                          </div>

                                          <div>
                                            <div class="inline-block">
                                              <button
                                                type="button"
                                                tabindex="-1"
                                                itemprop="award"
                                                class="item-components__badge--7dccd"
                                                aria-describedby="most-popular-badge-7452"
                                              >
                                                <span class="icon-ic icon-center item-components__icon--679f4" />

                                                <span class="item-components__value--357e4" />
                                              </button>
                                            </div>
                                          </div>

                                          <button
                                            type="button"
                                            class="reviews reviews--hover"
                                            tabindex="-1"
                                            itemprop="aggregateRating"
                                            itemscope="itemscope"
                                            data-qa="item-rating-details"
                                          >
                                            <span class="review">
                                              <span
                                                v-if="pricipalRating >= '5'"
                                                class="inline-block item-components__size-sm--9048f item-components__color-4--ff39f rating-pill"
                                              >
                                                <span
                                                  class="item-components__pillValue--c8d1a item-components__value-sm--4b5df item-components__pillValue--c8d1a"
                                                  itemprop="ratingValue"
                                                >
                                                  {{ pricipalRating }}
                                                </span>
                                              </span>

                                              <span
                                                v-else
                                                class="inline-block item-components__size-sm--9048f item-components__color-1--facf7 rating-pill"
                                              >
                                                <span
                                                  class="item-components__pillValue--c8d1a item-components__value-sm--4b5df item-components__pillValue--c8d1a"
                                                  itemprop="ratingValue"
                                                >
                                                  {{ pricipalRating }}
                                                </span>
                                              </span>

                                              <span class="details-paragraph details-paragraph--rating">
                                                <strong class="rating-number">{{ ratingText }} </strong>(1977opiniones)
                                              </span>

                                              <span class="icon-ic slideout-toggle-ic icon-contain">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  focusable="false"
                                                  tabindex="-1"
                                                  width="10"
                                                  height="6"
                                                  viewBox="0 0 10 6"
                                                >
                                                  <path
                                                    class="svg-color--primary"
                                                    d="M0 1A.999.999 0 011.707.293L5 3.586 8.293.293a.999.999 0 111.414 1.414l-4 4a.999.999 0 01-1.414 0l-4-4A.997.997 0 010 1"
                                                    fill="#697379"
                                                    fill-rule="evenodd"
                                                  />
                                                </svg>
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="item__slideout">
                                    <div
                                      id="js_slideout_7452"
                                      class="slo-wrp clearfix slo-wrp--active"
                                    >
                                      <div style="">
                                        <div class="slo-base-wrp">
                                          <div class="slo-base slo-base--rating">
                                            <article class="sl-box slo-rating-overall">
                                              <header class="sl-box__header">
                                                <h3
                                                  class="sl-box__title"
                                                  style="display: flex !important"
                                                >
                                                  Valoración
                                                </h3>
                                              </header>

                                              <div class="sl-box__content">
                                                <div class="clearfix">
                                                  <div>
                                                    <div class="rat-index">
                                                      <span
                                                        v-if="pricipalRating >= '5'"
                                                        class="inline-block item-components__size-lg--f58f7 item-components__color-4--ff39f rating-pill"
                                                      >
                                                        <span
                                                          class="item-components__pillValue--c8d1a item-components__value-lg--8874e item-components__pillValue--c8d1a"
                                                          itemprop="ratingValue"
                                                        >
                                                          {{ pricipalRating }}
                                                        </span>
                                                      </span>

                                                      <span
                                                        v-else
                                                        class="inline-block item-components__size-lg--f58f7 item-components__color-1--facf7 rating-pill"
                                                      >
                                                        <span
                                                          class="item-components__pillValue--c8d1a item-components__value-lg--8874e item-components__pillValue--c8d1a"
                                                          itemprop="ratingValue"
                                                        >
                                                          {{ pricipalRating }}
                                                        </span>
                                                      </span>

                                                      <div class="">
                                                        <div
                                                          class="rat-index__partner-link"
                                                        >
                                                          <b class="rat-index__partner-link-label">
                                                            trivago Rating Index
                                                          </b>&nbsp;
                                                          <sup>®</sup>
                                                          &nbsp;
                                                          a partir de
                                                          <b class="rat-index__partner-review-count">1977</b>
                                                          opiniones en Internet
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      data-hook="boxContent"
                                                      class="rat-detail v-tablet-wide-block"
                                                    >
                                                      <div class="rat-chart">
                                                        <div class="rat-chart__group rat-chart__group--collapsed">
                                                          <div class="rat-chart__item">
                                                            <span class="rat-chart__label">
                                                              Ubicación
                                                            </span>
                                                            <span class="rat-chart__bar">
                                                              <span class="rat-chart__bar-holder">
                                                                <span
                                                                  v-if="pricipalRating >= '5'"
                                                                  class="rat-chart__bar-content rat-color--4"
                                                                  style="width: 82%;"
                                                                />

                                                                <span
                                                                  v-else
                                                                  class="rat-chart__bar-content rat-color--1"
                                                                  style="width: 23%;"
                                                                />
                                                              </span>
                                                            </span>

                                                            <span class="rat-chart__number">
                                                              <span class="rat-chart__score-string">
                                                                {{ ratingText }}
                                                              </span>

                                                              <span class="parentesis-wrapper">
                                                                {{ ratingUbi }}/10
                                                              </span>
                                                            </span>
                                                          </div>

                                                          <div class="rat-chart__item">
                                                            <span class="rat-chart__label">
                                                              Habitaciones
                                                            </span>

                                                            <span class="rat-chart__bar">
                                                              <span class="rat-chart__bar-holder">
                                                                <span
                                                                  v-if="pricipalRating >= '5'"
                                                                  class="rat-chart__bar-content rat-color--4"
                                                                  style="width: 81%;"
                                                                />

                                                                <span
                                                                  v-else
                                                                  class="rat-chart__bar-content rat-color--2"
                                                                  style="width: 30%;"
                                                                />
                                                              </span>
                                                            </span>

                                                            <span class="rat-chart__number">
                                                              <span class="rat-chart__score-string">
                                                                {{ ratingText }}
                                                              </span>

                                                              <span class="parentesis-wrapper">
                                                                {{ ratingHab }}/10
                                                              </span>
                                                            </span>
                                                          </div>

                                                          <div class="rat-chart__item">
                                                            <span class="rat-chart__label">
                                                              Servicio
                                                            </span>

                                                            <span class="rat-chart__bar">
                                                              <span class="rat-chart__bar-holder">
                                                                <span
                                                                  v-if="pricipalRating >= '5'"
                                                                  class="rat-chart__bar-content rat-color--5"
                                                                  style="width: 85%;"
                                                                />

                                                                <span
                                                                  v-else
                                                                  class="rat-chart__bar-content rat-color--1"
                                                                  style="width: 27%;"
                                                                />
                                                              </span>
                                                            </span>

                                                            <span class="rat-chart__number">
                                                              <span class="rat-chart__score-string">
                                                                {{ ratingText }}
                                                              </span>

                                                              <span class="parentesis-wrapper">
                                                                {{ ratingServ }}/10
                                                              </span>
                                                            </span>
                                                          </div>

                                                          <div class="rat-chart__item">
                                                            <span class="rat-chart__label">
                                                              Limpieza
                                                            </span>

                                                            <span class="rat-chart__bar">
                                                              <span class="rat-chart__bar-holder">
                                                                <span
                                                                  v-if="pricipalRating >= '5'"
                                                                  class="rat-chart__bar-content rat-color--5"
                                                                  style="width: 87%;"
                                                                />

                                                                <span
                                                                  v-else
                                                                  class="rat-chart__bar-content rat-color--1"
                                                                  style="width: 22%;"
                                                                />
                                                              </span>
                                                            </span>

                                                            <span class="rat-chart__number">
                                                              <span class="rat-chart__score-string">
                                                                {{ ratingText }}
                                                              </span>

                                                              <span class="parentesis-wrapper">
                                                                {{ ratingLimp }}/10
                                                              </span>
                                                            </span>
                                                          </div>

                                                          <div class="rat-chart__item">
                                                            <span class="rat-chart__label">
                                                              Calidad-precio
                                                            </span>

                                                            <span class="rat-chart__bar">
                                                              <span class="rat-chart__bar-holder">
                                                                <span
                                                                  v-if="pricipalRating >= '5'"
                                                                  class="rat-chart__bar-content rat-color--4"
                                                                  style="width: 81%;"
                                                                />

                                                                <span
                                                                  v-else
                                                                  class="rat-chart__bar-content rat-color--1"
                                                                  style="width: 29%;"
                                                                />
                                                              </span>
                                                            </span>

                                                            <span class="rat-chart__number">
                                                              <span class="rat-chart__score-string">
                                                                {{ ratingText }}
                                                              </span>

                                                              <span class="parentesis-wrapper">
                                                                {{ ratingCalPre }}/10
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>

                                            <article class="sl-box slo-rat-opinion">
                                              <header class="sl-box__header text-overflow">
                                                <h3 class="sl-box__title">
                                                  Opiniones recientes
                                                </h3>
                                              </header>

                                              <div class="sl-box__content">
                                                <div class="clearfix">
                                                  <div>
                                                    <div role="tabpanel">
                                                      <div
                                                        v-for="(item,index) in roomInfo.data.commentsArray"
                                                        :key="index"
                                                        itemprop="review"
                                                        itemscope="itemscope"
                                                        class="sl-review sl-review--type-1 sl-review--index-0 clearfix pos-relative w-100 ta-leading"
                                                      >
                                                        <blockquote class="sl-review__wrp mb-0 font-trv-maincolor-04 clearfix">
                                                          <header class="sl-review__meta">
                                                            <span class="inline-block item-components__size-med--cfc29 item-components__disabled--17ddc rating-pill">
                                                              <meta
                                                                itemprop="ratingValue"
                                                                content="0"
                                                              >
                                                              <span class="item-components__pillValue--c8d1a item-components__value-med--9301a item-components__pillValue--c8d1a">
                                                                  <img
                                                                    class="trivagoAvatar"
                                                                    :src="loadImageServer(item[0].image)"
                                                                    data-component="image-fallback"
                                                                    data-fallback-src="#"
                                                                    alt=""
                                                                    aria-hidden="true"
                                                                  >
                                                              </span>
                                                            </span>
                                                            <p class="sl-review__no-rating">
                                                              {{ item[0].rating }}/10
                                                            </p>

                                                            <div class="sl-review__username pos-relative">
                                                              <cite
                                                                class="sl-review__data p-0"
                                                                itemprop="author"
                                                              >
                                                                {{ item[0].author }}
                                                              </cite>

                                                              <time
                                                                class="sl-review__data p-0 inline-block"
                                                                itemprop="dateCreated"
                                                                datetime="2019-10"
                                                              >
                                                                Fecha de la opinión:  {{ item[0].submittedAt }}
                                                              </time>
                                                            </div>
                                                          </header>

                                                          <div class="sl-review__content clearfix">
                                                            <h5
                                                              dir="auto"
                                                              class="sl-review__title pb-gutter-half sl-review__title--ltr"
                                                            >
                                                              <q class="font-small fs-italic">
                                                                {{ item[0].title }}
                                                              </q>
                                                            </h5>

                                                            <p
                                                              dir="auto"
                                                              class="sl-review__summary m-0 w-100 sl-review__summary--ltr"
                                                              v-html="item[0].comment"
                                                            />

                                                            <div class="sl-review__bottom">
                                                              <ul class="sl-review-tags" />
                                                            </div>
                                                          </div>
                                                        </blockquote>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </article>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </li>
                          </ol>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <ButtonArea
        :page-order="webPosition"
        @nextWeb="getNextWeb()"
        @previousPageWeb="getPreviousWeb()"
        @finish="getFinishWeb()"
        @selectHotel="selectHotel('trivago')"
      />
    </div>
  </body>
</template>

<script>
import ButtonArea from '@/components/ButtonArea';
import { get } from 'vuex-pathify';
import fullMethods from '@/mixins/fullMethods';

export default {
  components: {
    ButtonArea,
  },

  mixins: [fullMethods],

  data() {
    return {
      webPosition: undefined,
      webOrder: undefined,
      roomInfo: undefined,
      roomTitle: undefined,
      roomImage: undefined,
      roomAddress: undefined,
      pricipalRating: undefined,
      ratingText: undefined,
      ratingUbi: undefined,
      ratingHab: undefined,
      ratingServ: undefined,
      ratingLimp: undefined,
      ratingCalPre: undefined,
      roomComments: undefined,
      timer: undefined,
      elapsedTime: 0,
    };
  },

  computed: {
    ...get('experimentModule', ['getWebOrder']),
  },

  async created() {
    this.webOrder = this.getWebOrder();
    if (!this.webOrder) {
      this.webOrder = JSON.parse(localStorage.getItem('adn'));
    }
    this.webPosition = this.webOrder.indexOf('trivago');

    const payload = {
      webPos: this.webPosition,
      expId: localStorage.getItem('tripsimulator'),
    };

    this.roomInfo = await this.$store.dispatch('experimentModule/fetchComments', payload);

    this.roomTitle = this.roomInfo.data.roomInfo.name;
    this.roomAddress = this.roomInfo.data.roomInfo.address;
    this.roomImage = this.roomInfo.data.roomInfo.image;
    this.calculateRank();
    this.startTimer();
  },

  methods: {
    startTimer() {
      if (localStorage.getItem('tripsimulatorTimerTrivago') !== null) {
        this.elapsedTime = parseInt(localStorage.getItem('tripsimulatorTimerTrivago'), 10);
      }

      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
    },

    getNextWeb() {
      this.$router.push({ name: this.webOrder[this.webPosition + 1] });
      this.addNextStepCount();
      this.setTrivagoTime();
    },

    getPreviousWeb() {
      this.$router.push({ name: this.webOrder[this.webPosition - 1] });
      this.addBackStepCount();
      this.setTrivagoTime();
    },

    setTrivagoTime() {
      this.stop();
      localStorage.setItem('tripsimulatorTimerTrivago', this.elapsedTime.toString());
    },

    calculateRank() {
      const { rating } = this.roomInfo.data.commentsArray[0][0];
      if (rating >= 5) {
        this.ratingText = 'Muy Bueno';
        this.pricipalRating = this.calculatePrincipalRating();
        this.ratingUbi = '8,2';
        this.ratingHab = '8,1';
        this.ratingServ = '8,5';
        this.ratingLimp = '8,7';
        this.ratingCalPre = '8,1';
      } else {
        this.ratingText = 'Muy Malo';
        this.pricipalRating = this.calculatePrincipalRating();
        this.ratingUbi = '2,3';
        this.ratingHab = '3';
        this.ratingServ = '2,7';
        this.ratingLimp = '2,2';
        this.ratingCalPre = '2,9';
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.trivagoAvatar{
  position: absolute;
  top: 6px;
  right: 0px;
  bottom: 0;
  left: 7px;
  border-radius: 50%;
  width: 8%;
  height: 33%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  background-position: 40%;
  background-repeat: no-repeat;
  background-color: #f2f2f2;
}
</style>
