<template>
  <body
    v-if="roomInfo"
    id="BODY_BLOCK_JQUERY_REFLOW"
    class="rebrand_2017 js_logging desktop_web Hotel_Review"
    data-tab="TABS_TOP"
  >
    <div class="header global-header">
      <div class="masthead">
        <!-- PLACEMENT horizon_ad -->

        <div
          id="taplc_global_nav_0"
          class="ppr_rup ppr_priv_global_nav"
          data-placement-name="global_nav"
        >
          <div
            id="global-nav-no-refresh-1"
            class="global-nav-no-refresh"
          >
            <!-- PLACEMENT global_nav_menus -->
            <div
              id="taplc_global_nav_menus_0"
              class="ppr_rup ppr_priv_global_nav_menus"
              data-placement-name="global_nav_menus"
            >
              <!-- PLACEMENT global_nav_menus -->
              <div class="hidden">
                <div class="global-nav-menus-container">
                  <div class="tabsBar" />
                </div>
              </div>
            </div>
          </div>
          <!-- PLACEMENT global_nav_component -->
          <div
            id="taplc_global_nav_component_0"
            class="ppr_rup ppr_priv_global_nav_component"
            data-placement-name="global_nav_component"
          >
            <div
              id="component_11"
              class="react-container"
              data-component-props="page-manifest"
              data-component="@ta/brand.legacy-header"
              data-component-init="data-component-init"
            >
              <div class="DBxAQ">
                <div class="bBfFc">
                  <header class="foCGN Cj">
                    <div class="dqRmR cyevx dcDXR dJjeH">
                      <nav class="oMuFD">
                        <button
                          class="cFWEh u j _T _S S8 H0 c _G G_ B- M0 P0"
                          title="Menú"
                          aria-label="Menú"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="1em"
                            height="1em"
                            class="fecdL d Vb wQMPa"
                          >
                            <path d="M2.038 4.511H22v2.496H2.038zM2 10.752h19.962v2.497H2zM2.014 16.992h19.962v2.496H2.014z" />
                          </svg>
                        </button>

                        <a
                          class="eWMgE F0 ddFHE"
                          href="https://www.tripadvisor.es/"
                        >
                          <img
                            class="bDssy _R h"
                            src="~@/assets/img/Tripadvisor_lockup_horizontal_secondary_registered.svg"
                            alt="Tripadvisor"
                          >
                        </a>

                        <div class="fUFMI">
                          <div class="hsGzz">
                            <div
                              class="weiIG Z0 Wh bZZEX"
                              data-test-attribute="typeahead-SINGLE_SEARCH_NAV"
                            >
                              <form
                                role="search"
                                class="bmTdH o"
                                action="/Search"
                              >
                                <input
                                  type="search"
                                  autocomplete="off"
                                  autocorrect="off"
                                  autocapitalize="none"
                                  spellcheck="false"
                                  required=""
                                  name="q"
                                  class="fhEMT _G B- z _J Cj R0"
                                  title="Buscar"
                                  role="searchbox"
                                  aria-label="Buscar"
                                  aria-controls="typeahead_results"
                                  aria-autocomplete="list"
                                ><input
                                  type="hidden"
                                  name="searchSessionId"
                                  value="5E4946BF12F904F64D210CC52ED59FDA1633587117261ssid"
                                >

                                <input
                                  type="hidden"
                                  name="searchNearby"
                                  value="false"
                                >

                                <input
                                  type="hidden"
                                  name="geo"
                                  value="187443"
                                >
                                <button
                                  type="button"
                                  class="fcKuM _G _H B- _S t u j Cj eRhKG"
                                  title="Volver"
                                  aria-label="Volver"
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="1em"
                                    height="1em"
                                    class="fecdL d Vb wQMPa"
                                  >
                                    <path
                                      d="M10.304 3.506l-8.048 8.047a.644.644 0 000 .895l8.048 8.047a.624.624 0 00.883 0l.882-.883a.624.624 0 000-.883l-5.481-5.48h14.714a.625.625 0 00.623-.625v-1.248a.624.624 0 00-.623-.624H6.588l5.481-5.481a.624.624 0 000-.883l-.882-.883a.623.623 0 00-.883-.004c-.001.002-.002.003 0 .005z"
                                    />
                                  </svg>
                                </button>

                                <button
                                  type="submit"
                                  formaction="/Search"
                                  class="fcKuM _G _H B- _S t u j Cj dobQt"
                                  title="Buscar"
                                  aria-label="Buscar"
                                  tabindex="-1"
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="1em"
                                    height="1em"
                                    class="fecdL d Vb wQMPa"
                                  >
                                    <path d="M22 20.514l-5.517-5.519a8.023 8.023 0 001.674-4.904c0-4.455-3.624-8.079-8.079-8.079C5.624 2.012 2 5.636 2 10.091s3.624 8.079 8.079 8.079a8.03 8.03 0 004.933-1.695l5.512 5.514L22 20.514zm-11.921-4.431c-3.305 0-5.993-2.688-5.993-5.992s2.688-5.992 5.993-5.992a6 6 0 015.992 5.992 6 6 0 01-5.992 5.992z" />
                                  </svg>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>

                        <div class="dirtB">
                          <div class="MwbTc w _T" />
                        </div>
                      </nav>
                    </div>
                  </header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page ">
      <div id="atf_header_wrap">
        <div
          id="atf_header"
          class="ui_container is-fluid page-section accessible_red_3"
        >
          <div
            id="taplc_hotel_review_atf_hotel_info_web_component_0"
            class="ppr_rup ppr_priv_hotel_review_atf_hotel_info_web_component"
            data-placement-name="hotel_review_atf_hotel_info_web_component"
          >
            <div
              id="component_6"
              class="react-container"
              data-component-props="page-manifest"
              data-component="@ta/hotels.hotel-review-atf-info"
              data-component-init="data-component-init"
            >
              <div
                class="cfeJC _T"
                data-test-target="hr-aft-info"
              >
                <div class="ui_columns is-multiline is-mobile">
                  <div class="ui_column is-12-tablet is-9-mobile bgVDn P">
                    <div class="eIsCM f">
                      <h1
                        id="HEADING"
                        class="fkWsC b d Pn"
                      >
                        {{ roomTitle }}
                      </h1>
                    </div>

                    <div class="eCOqA f Mc">
                      <a
                        class="fbhUA q eRJGA _T Gi"
                        href="#REVIEWS"
                      >
                        <span
                          v-if="pricipalRating >= 5"
                          class="ui_bubble_rating bubble_40"
                        />

                        <span
                          v-else
                          class="ui_bubble_rating bubble_20"
                        />

                        <span class="HFUqL">
                          2116 opiniones
                        </span>
                      </a>
                    </div>
                  </div>

                  <div class="ui_column is-12-tablet is-3-mobile dvOrL f">
                    <div class="fqjXu f">
                      <div class="cJQXM q">
                        <div class="dftTG d Vb">
                          <div class="bGFob u _S ggmSn">
                            <span class="hikBf Vm cigfO Z1 xydzs">
                              <span class="bnAdE">
                                <span class="xAttt t l dgpnr cfoDI">

                                </span>

                                <span class="xAttt t l bfYnp cfoDI">

                                </span>
                              </span>
                            </span>
                          </div>
                        </div>

                        <div class="eFVBW d _T Vt">

                        </div>
                      </div>

                      <div class="fSXiz">
                        <div class="ewock">
                          <div class="epMGr cLZgU">
                            <div class="ApqWZ S4 H3 f u eEkxn">
                              <i class="fas fa-map-marker-alt" />
                              <span class="eWZDY _S eCdbd yYjkv">
                                <span class="ceIOZ yYjkv">
                                  {{ roomAddress }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="MAIN"
        class="delineation accessible_red_3"
      >
        <!-- llegada salida y foto -->
        <div
          id="atf_meta"
          class="ui_container is-fluid page-section"
        >
          <!-- PLACEMENT resp_hr_atf_content -->
          <div
            id="taplc_resp_hr_atf_content_0"
            class="ppr_rup ppr_priv_resp_hr_atf_content"
            data-placement-name="resp_hr_atf_content"
          >
            <div class="is-hidden-mobile">
              <!-- PLACEMENT resp_hr_atf_new_hotel_banner -->
              <div
                id="taplc_resp_hr_atf_new_hotel_banner_0"
                class="ppr_rup ppr_priv_resp_hr_atf_new_hotel_banner"
                data-placement-name="resp_hr_atf_new_hotel_banner"
              >
                <div
                  id="component_37"
                  class="react-container"
                  data-component-props="page-manifest"
                  data-component="@ta/hotels.hotel-review-new-hotel-banner"
                  data-component-init="data-component-init"
                />
              </div>
            </div>

            <div class="atf_meta_and_photos ui_columns is-mobile ">
              <!-- valoraciones -->
              <div
                id="taplc_resp_hr_ad_wrapper_upper_block_0"
                class="ppr_rup ppr_priv_resp_hr_ad_wrapper"
                data-placement-name="resp_hr_ad_wrapper:upper_block"
              >
                <div class="column_wrap ui_columns is-multiline ">
                  <div class="content_column ui_column is-8-desktop is-12">
                    <!-- PLACEMENT resp_hr_new_hotel_preview -->
                    <div
                      id="taplc_resp_hr_new_hotel_preview_0"
                      class="ppr_rup ppr_priv_resp_hr_new_hotel_preview"
                      data-placement-name="resp_hr_new_hotel_preview"
                    >
                      <div
                        id="component_31"
                        class="react-container"
                        data-component-props="page-manifest"
                        data-component="@ta/hotels.hotel-review-new-hotel-preview"
                        data-component-init="data-component-init"
                      />
                    </div><!-- PLACEMENT hr_covid_react:above_about --><div
                      id="taplc_hr_covid_react_above_about_0"
                      class="ppr_rup ppr_priv_hr_covid_react"
                      data-placement-name="hr_covid_react:above_about"
                    >
                      <div
                        id="component_38"
                        class="react-container"
                        data-component-props="page-manifest"
                        data-component="@ta/location-review.safety-section"
                        data-component-init="data-component-init"
                      />
                    </div><!-- PLACEMENT hr_tripplus_cms_banner:above_about --><div
                      id="taplc_hr_tripplus_cms_banner_above_about_0"
                      class="ppr_rup ppr_priv_hr_tripplus_cms_banner"
                      data-placement-name="hr_tripplus_cms_banner:above_about"
                    >
                      <div
                        id="component_19"
                        class="react-container"
                        data-component-props="page-manifest"
                        data-component="@ta/location-review.tripplus-cms-banner-hr-above-about"
                        data-component-init="data-component-init"
                      />
                    </div>
                    <!-- PLACEMENT about_with_photos_react -->
                    <div
                      id="taplc_about_with_photos_react_0"
                      class="ppr_rup ppr_priv_about_with_photos_react"
                      data-placement-name="about_with_photos_react"
                    >
                      <div
                        id="component_14"
                        class="react-container"
                        data-component-props="page-manifest"
                        data-component="@ta/hotels.hotel-review-about-with-photos"
                        data-component-init="data-component-init"
                      >
                        <div>
                          <div>
                            <div
                              id="ABOUT_TAB"
                              class="eZQpm _T Z BB"
                              data-tab="TABS_ABOUT"
                              data-section-signature="about"
                              style="width:36rem"
                            >
                              <div class="bvNLM eDkSl Z BB">
                                <h2 class="bCtzR F1 b">
                                  Información
                                </h2>
                              </div><div class="ui_columns uXLfx">
                                <div class="ui_column  ">
                                  <div
                                    v-if="pricipalRating >= 5"
                                    class="bSlOX Xe f P"
                                  >
                                    <span class="bvcwU P">{{ pricipalRating }}</span>
                                    <div
                                      class="eJnhj P"
                                    >
                                      <div class="cNJsa">
                                        Excelente
                                      </div>
                                      <span class="ui_bubble_rating bubble_50" />
                                      <span class="btQSs q Wi z Wc">122 opiniones</span>
                                    </div>
                                  </div>

                                  <div
                                    v-else
                                    class="bSlOX Xe f P"
                                  >
                                    <span class="bvcwU P">{{ pricipalRating }}</span>
                                    <div
                                      class="eJnhj P"
                                    >
                                      <div class="cNJsa">
                                        Muy malo
                                      </div>
                                      <span class="ui_bubble_rating bubble_20" />
                                      <span class="btQSs q Wi z Wc">123 opiniones</span>
                                    </div>
                                  </div>

                                  <div
                                    v-if="pricipalRating >= 5"
                                    class="cmZRz f"
                                  >
                                    <span class="ui_bubble_rating bubble_45" />

                                    <div class="bjRcr">
                                      Limpieza
                                    </div>
                                  </div>

                                  <div
                                    v-else
                                    class="cmZRz f"
                                  >
                                    <span class="ui_bubble_rating bubble_15" />

                                    <div class="bjRcr">
                                      Limpieza
                                    </div>
                                  </div>

                                  <div
                                    v-if="pricipalRating >= 5"
                                    class="cmZRz f"
                                  >
                                    <span class="ui_bubble_rating bubble_50" />

                                    <div class="bjRcr">
                                      Servicio
                                    </div>
                                  </div>

                                  <div class="cmZRz f">
                                    <span class="ui_bubble_rating bubble_25" />

                                    <div class="bjRcr">
                                      Servicio
                                    </div>
                                  </div>

                                  <div
                                    v-if="pricipalRating >= 5"
                                    class="cmZRz f"
                                  >
                                    <span class="ui_bubble_rating bubble_50" />

                                    <div class="bjRcr">
                                      Relación calidad-precio
                                    </div>
                                  </div>

                                  <div
                                    v-else
                                    class="cmZRz f"
                                  >
                                    <span class="ui_bubble_rating bubble_20" />

                                    <div class="bjRcr">
                                      Relación calidad-precio
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="ui_column photoBlocks is-multiline ui_columns">
                <div class="ui_column is-12">
                  <!-- PLACEMENT resp_hr_atf_photos_component:mas_media_window -->
                  <div
                    id="taplc_resp_hr_atf_photos_component_mas_media_window_0"
                    class="ppr_rup ppr_priv_resp_hr_atf_photos_component"
                    data-placement-name="resp_hr_atf_photos_component:mas_media_window"
                  >
                    <div
                      id="component_40"
                      class="react-container"
                      data-component-props="page-manifest"
                      data-component="@ta/hotels.hotel-review-atf-photos-media-window"
                      data-component-init="data-component-init"
                    >
                      <div
                        class="eJWTD h cdIfN _T f"
                        data-section-signature="photo_viewer"
                      >
                        <div class="bliPr f fcrnc fHagO fMEaT">
                          <div class="dAvLH f e">
                            <div class="dwXDm e">
                              <div
                                class="dtDIl carousel"
                                style="width:100%;height:100%"
                              >
                                <ul
                                  class="cAcWl"
                                  style="background-color:black"
                                >
                                  <li class="eVXXO s _U gaMjJ">
                                    <div
                                      class="bxLAc GA"
                                      style="height:100%;width:100%;position:relative"
                                    >
                                      <img
                                        class="bMGfJ _Q t _U s l bnegk"
                                        :src="loadImageServer(roomImage)"
                                        alt=""
                                        style="height:100%;width:100%;object-fit:cover"
                                      >
                                    </div>
                                  </li>

                                  <li class="eVXXO s _U">
                                    <div
                                      class="bxLAc GA"
                                      style="height: 100%; width: 100%; position: relative;"
                                    >
                                      <img
                                        class="bMGfJ _Q t _U s l bnegk"
                                        :src="loadImageServer(roomImage)"
                                        alt=""
                                        style="height: 100%; width: 100%; object-fit: cover;"
                                      >
                                    </div>
                                  </li>

                                  <li class="eVXXO s _U">
                                    <div
                                      class="bxLAc GA"
                                      style="height: 100%; width: 100%; position: relative;"
                                    >
                                      <img
                                        class="bMGfJ _Q t _U s l bnegk"
                                        :src="loadImageServer(roomImage)"
                                        alt=""
                                        style="height: 100%; width: 100%; object-fit: cover;"
                                      >
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="btf_wrap"
          class="ui_container is-fluid page-section"
        >
          <!-- Opiniones -->
          <div
            id="taplc_resp_hr_ad_wrapper_lower_block_0"
            class="ppr_rup ppr_priv_resp_hr_ad_wrapper"
            data-placement-name="resp_hr_ad_wrapper:lower_block"
          >
            <div class="column_wrap ui_columns is-multiline ">
              <div class="content_column ui_column is-12">
                <!-- PLACEMENT hr_community_content:ssronly -->
                <div
                  id="taplc_hr_community_content_ssronly_0"
                  class="ppr_rup ppr_priv_hr_community_content"
                  data-placement-name="hr_community_content:ssronly"
                >
                  <div
                    id="component_16"
                    class="react-container"
                    data-component-props="page-manifest"
                    data-component="@ta/common.ssronly"
                    data-component-init="data-component-init"
                  >
                    <div
                      class="was-ssr-only"
                      data-ssr-done="true"
                    >
                      <div>
                        <div
                          id="COMMUNITYCONTENT"
                          class="blMNV _T Z BB fYYBB"
                          data-tab="TABS_COMMUNITYCONTENT"
                          data-section-signature="communityContent"
                        >
                          <div class="bvNLM eDkSl Z BB">
                            <h2 class="bCtzR F1 b">
                              Opiniones
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div
                        class=""
                        data-test-target="reviews-tab"
                      >
                        <div class="fgrRH Gi Z BB">
                          <div class="ui_columns ">
                            <div class="ui_column is-5 is-12-mobile">
                              <div class="cMImN b S4 H4">
                                Puntuación de viajeros
                              </div>
                              <ul class="mPill w S4">
                                <li class="ui_checkbox dQNlC">
                                  <input
                                    id="ReviewRatingFilter_5"
                                    type="checkbox"
                                    value="5"
                                  >
                                  <label
                                    for="ReviewRatingFilter_5"
                                    class="Oixff Vm _S fxJOd"
                                  >
                                    Excelente
                                  </label>

                                  <span class="enrAK w">
                                    <span class="ersIo _T w R2 _S">
                                      <span
                                        v-if="pricipalRating >= 5"
                                        class="dZKtJ R2"
                                        style="width: 31.7258%;"
                                      />

                                      <span
                                        v-else
                                        class="dZKtJ R2"
                                        style="width: 11.7258%;"
                                      />
                                    </span>
                                  </span>

                                  <span class="cpqJw">
                                    671
                                  </span>
                                </li>

                                <li class="ui_checkbox dQNlC">
                                  <input
                                    id="ReviewRatingFilter_4"
                                    type="checkbox"
                                    value="4"
                                  >
                                  <label
                                    for="ReviewRatingFilter_4"
                                    class="Oixff Vm _S fxJOd"
                                  >
                                    Muy bueno
                                  </label>

                                  <span class="enrAK w">
                                    <span class="ersIo _T w R2 _S">
                                      <span
                                        v-if="pricipalRating >= 5"
                                        class="dZKtJ R2"
                                        style="width: 47.3759%;"
                                      />

                                      <span
                                        v-else
                                        class="dZKtJ R2"
                                        style="width: 27.3759%;"
                                      />
                                    </span>
                                  </span>

                                  <span class="cpqJw">
                                    1002
                                  </span>
                                </li>

                                <li class="ui_checkbox dQNlC">
                                  <input
                                    id="ReviewRatingFilter_3"
                                    type="checkbox"
                                    value="3"
                                  >
                                  <label
                                    for="ReviewRatingFilter_3"
                                    class="Oixff Vm _S fxJOd"
                                  >
                                    Normal
                                  </label>

                                  <span class="enrAK w">
                                    <span class="ersIo _T w R2 _S">
                                      <span
                                        class="dZKtJ R2"
                                        style="width: 15.7447%;"
                                      />
                                    </span>
                                  </span>

                                  <span class="cpqJw">
                                    333
                                  </span>
                                </li>

                                <li class="ui_checkbox dQNlC">
                                  <input
                                    id="ReviewRatingFilter_2"
                                    type="checkbox"
                                    value="2"
                                  >

                                  <label
                                    for="ReviewRatingFilter_2"
                                    class="Oixff Vm _S fxJOd"
                                  >
                                    Malo
                                  </label>

                                  <span class="enrAK w">
                                    <span class="ersIo _T w R2 _S">
                                      <span
                                        v-if="pricipalRating >= 5"
                                        class="dZKtJ R2"
                                        style="width: 3.12057%;"
                                      />

                                      <span
                                        v-else
                                        class="dZKtJ R2"
                                        style="width: 43.12057%;"
                                      />
                                    </span>
                                  </span>

                                  <span class="cpqJw">
                                    566
                                  </span>
                                </li>

                                <li class="ui_checkbox dQNlC">
                                  <input
                                    id="ReviewRatingFilter_1"
                                    type="checkbox"
                                    value="1"
                                  >
                                  <label
                                    for="ReviewRatingFilter_1"
                                    class="Oixff Vm _S fxJOd"
                                  >
                                    Pésimo
                                  </label>
                                  <span class="enrAK w">
                                    <span class="ersIo _T w R2 _S">
                                      <span
                                        v-if="pricipalRating >= 5"
                                        class="dZKtJ R2"
                                        style="width: 2.0331%;"
                                      />

                                      <span
                                        v-else
                                        class="dZKtJ R2"
                                        style="width: 12.0331%;"
                                      />
                                    </span>
                                  </span>

                                  <span class="cpqJw">
                                    43
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div
                            v-for="(item,index) in roomInfo.data.commentsArray"
                            :key="index"
                            class="cWwQK MC R2 Gi z Z BB dXjiy"
                            data-test-target="HR_CC_CARD"
                          >
                            <div class="xMxrO">
                              <div class="bJaRP h o">
                                <div class="bcaHz">
                                  <span>
                                    <img
                                      class="tripadvisorAvatar"
                                      :src="loadImageServer(item[0].image)"
                                      data-component="image-fallback"
                                      data-fallback-src="#"
                                      alt=""
                                      aria-hidden="true"
                                    >
                                    {{ item[0].author }} escribió una opinión {{ item[0].submittedAt }}
                                  </span>
                                </div>

                                <div class="BZmsN">
                                  <span class="fSiLz">
                                    <span class="default ShLyt small">
                                      <i class="fas fa-map-marker-alt" />
                                      España
                                    </span>
                                  </span>

                                  <span class="eUTJT">
                                    <span>
                                      <span class="ckXjS">
                                        4
                                      </span>
                                      contribuciones
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              class="cqoFv _T"
                              data-reviewid="811253221"
                            >
                              <div class="elFlG f O">
                                <div
                                  class="emWez F1"
                                  data-test-target="review-rating"
                                >
                                  <span
                                    v-if="pricipalRating >= 5"
                                    class="ui_bubble_rating bubble_45"
                                  />

                                  <span
                                    v-else
                                    class="ui_bubble_rating bubble_20"
                                  />
                                </div>
                              </div>

                              <div
                                class="fpMxB MC _S b S6 H5 _a"
                                dir="ltr"
                                data-test-target="review-title"
                              >
                                <span>
                                  <span>
                                    {{ item[0].title }}
                                  </span>
                                </span>
                              </div>

                              <div class="dovOW">
                                <div class="duhwe _T bOlcm dMbup">
                                  <div
                                    class="pIRBV _T"
                                    style="max-height: initial; line-break: normal; cursor: auto;"
                                  >
                                    <q class="XllAv H4 _a">
                                      <span
                                        v-html="item[0].comment"
                                      />
                                    </q>
                                  </div>
                                </div>

                                <div class="bzjij">
                                  <span class="euPKI _R Me S4 H3">
                                    <span class="CrxzX">
                                      Fecha de la estancia:
                                    </span>
                                    {{ item[0].submittedAt }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bottom_anchor" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ButtonArea
          :page-order="webPosition"
          @nextWeb="getNextWeb()"
          @previousPageWeb="getPreviousWeb()"
          @finish="getFinishWeb()"
          @selectHotel="selectHotel('tripadvisor')"
        />
      </div>
    </div>
  </body>
</template>

<script>
import ButtonArea from '@/components/ButtonArea';
import { get } from 'vuex-pathify';
import fullMethods from '@/mixins/fullMethods';

export default {
  components: {
    ButtonArea,
  },

  mixins: [fullMethods],

  data() {
    return {
      roomInfo: undefined,
      roomTitle: undefined,
      roomAddress: undefined,
      roomImage: undefined,
      pricipalRating: undefined,
      ratingText: undefined,
      ratingUbi: undefined,
      ratingHab: undefined,
      ratingServ: undefined,
      ratingLimp: undefined,
      ratingCalPre: undefined,
      roomComments: undefined,
      timer: undefined,
      elapsedTime: 0,
    };
  },

  computed: {
    ...get('experimentModule', ['getWebOrder']),
  },

  async created() {
    this.webOrder = this.getWebOrder();
    if (!this.webOrder) {
      this.webOrder = JSON.parse(localStorage.getItem('adn'));
    }
    this.webPosition = this.webOrder.indexOf('tripadvisor');

    const payload = {
      webPos: this.webPosition,
      expId: localStorage.getItem('tripsimulator'),
    };

    this.roomInfo = await this.$store.dispatch('experimentModule/fetchComments', payload);

    this.roomTitle = this.roomInfo.data.roomInfo.name;
    this.roomAddress = this.roomInfo.data.roomInfo.address;
    this.roomImage = this.roomInfo.data.roomInfo.image;
    this.calculateRank();
    this.startTimer();
  },

  methods: {
    startTimer() {
      if (localStorage.getItem('tripsimulatorTimerTripadvisor') !== null) {
        this.elapsedTime = parseInt(localStorage.getItem('tripsimulatorTimerTripadvisor'), 10);
      }

      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
    },

    getNextWeb() {
      this.$router.push({ name: this.webOrder[this.webPosition + 1] });
      this.addNextStepCount();
      this.setTripadvisorTime();
    },

    getPreviousWeb() {
      this.$router.push({ name: this.webOrder[this.webPosition - 1] });
      this.addBackStepCount();
      this.setTripadvisorTime();
    },

    setTripadvisorTime() {
      this.stop();
      localStorage.setItem('tripsimulatorTimerTripadvisor', this.elapsedTime.toString());
    },

    calculateRank() {
      const { rating } = this.roomInfo.data.commentsArray[0][0];
      if (rating >= 5) {
        console.log('si');
        this.ratingText = 'Muy Bueno';
        this.pricipalRating = this.calculatePrincipalRating();
        this.ratingUbi = '8,2';
        this.ratingHab = '8,1';
        this.ratingServ = '8,5';
        this.ratingLimp = '8,7';
        this.ratingCalPre = '8,1';
      } else {
        console.log('no');
        this.ratingText = 'Muy Malo';
        this.pricipalRating = this.calculatePrincipalRating();
        this.ratingUbi = '2,3';
        this.ratingHab = '3';
        this.ratingServ = '2,7';
        this.ratingLimp = '2,2';
        this.ratingCalPre = '2,9';
      }
      console.log('rating', this.pricipalRating);
    },
  },
};
</script>
<style lang="scss" scoped>

.tripadvisorAvatar{
  border-radius: 50%;
  width: 5%;
  height: 5%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  background-position: 40%;
  background-repeat: no-repeat;
  background-color: #f2f2f2;
}

</style>
