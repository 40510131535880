<template>
  <div
    v-if="texts"
    class="FinishView"
  >
    <p>
      {{ texts.data[0].finishTitle }}
    </p>

    <div
      class="bottom-block"
    >
      <p>
        {{ texts.data[0].finishPrimeText }}
      </p>

      <p>
        {{ texts.data[0].finishSecondText }}
      </p>

      <router-link
        :to="{ name: 'conditions'}"
        target="_blank"
      >
        Pulsa aquí para ver las condiciones
      </router-link>


      <div class="check-button">
        <input
          id="checkbox"
          v-model="checked"
          type="checkbox"
        >
        <label for="checkbox">Acepto las condiciones y quiero participar en el sorteo</label>
      </div>


      <div
        v-if="visible"
        class="input-block"
      >
        <div
          v-if="checked"
        >
          <input
            id="email"
            v-model="email"
            class="email-input"
            type="email"
            placeholder="Please enter your email here"
            required
            @blur="validateEmail()"
          >
          <p>
            <label
              v-if="!validate"
              for="email"
            >Introduzca un email correcto
            </label>

            <BaseButton
              v-if="validate"
              class="buttonActive"
              size="l"
              @click="sendMail()"
            >
              Enviar Email
            </BaseButton>
          </p>
        </div>

        <div v-else>
          <p>
            Debes aceptar las condiciones para poder continuar
          </p>
        </div>
      </div>

      <div v-else>
        Se han registrado los datos correctamente
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  data() {
    return {
      checked: false,
      email: undefined,
      visible: true,
      validate: false,
      texts: undefined,
      isModalVisible: false,
    };
  },

  computed: {
    ...get('experimentModule', ['getTexts']),
  },

  async created() {
    this.texts = this.getTexts();
    if (!this.texts) this.texts = await this.$store.dispatch('experimentModule/fetchTexts');
  },

  methods: {
    closeModal() {
      this.isModalVisible = false;
    },

    openModal() {
      this.isModalVisible = true;
    },

    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.validate = true;
      } else {
        this.validate = false;
      }
    },

    sendMail() {
      const payload = {
        email: this.email,
        id: localStorage.getItem('tripsimulator'),
      };

      this.$store.dispatch('experimentModule/sendEmail', payload)
        .then(() => {
          this.$router.push({ name: 'goodbye' });
        })

        .catch((error) => {
          if (error.response.status === 400) {
            this.$router.push({ name: 'help' });
          }
        })

        .finally(() => {
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.FinishView{
  margin: 3rem;
  padding: 2rem 4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #03a9f42b;

  .check-button{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p{
    font-size:1.25rem;
  }

  li{
    font-size:1.5rem;
    margin-bottom: .5rem;
  }

  .bottom-block{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buttonActive{
    margin-top: .5rem;
  }

  .input-block{
    margin-top: 2.5rem;
  }

  .email-input{
    width: 100%;
    outline: 0;
    border-width: 0 0 2px;
    border-color: blue;
  }
}
</style>
