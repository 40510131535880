<template>
  <div
    class="FinishView"
  >
    <h2>
      Muchas gracias por participar
    </h2>
  </div>
</template>

<script>

export default {

};
</script>

<style lang="scss" scoped>
.FinishView{
  margin: 3rem;
  padding: 2rem 4rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #03a9f42b;

  .check-button{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p{
    font-size:1.25rem;
  }

  li{
    font-size:1.5rem;
    margin-bottom: .5rem;
  }

  .bottom-block{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buttonActive{
    margin-top: .5rem;
  }

  .input-block{
    margin-top: 2.5rem;
  }

  .email-input{
    width: 100%;
    outline: 0;
    border-width: 0 0 2px;
    border-color: blue;
  }
}
</style>
