<template>
  <div
    v-if="cities && texts"
    class="HomeView"
  >
    <h1>{{ texts.data[0].primeTitle }}</h1>

    <p>
      {{ texts.data[0].primeFistText }}
    </p>

    <p>
      <strong>Elija su destino</strong>
    </p>

    <div>
      <ul>
        <li
          v-for="(item,index) in cities.data"
          :key="index"
        >
          <strong>
            <a
              class="username"
              @click="setLocation(item.id)"
            >
              {{ item.name }}
            </a>
          </strong>
        </li>
      </ul>
    </div>

    <div v-if="ended">
      <p>
        Ya has participado en el sorteo
      </p>
    </div>

    <div
      v-else
      class="bottom-block"
    >
      <p>
        {{ texts.data[0].primeSecondText }}
      </p>

      <p
        v-if="!selectedDestiny"
      >
        No se ha elegido ningún destino aún. Pulsa sobre un destino para comenzar.
      </p>

      <p
        v-else
      >
        El destino elegido es <strong>{{ cityName }}</strong>
      </p>
    </div>

    <BaseButton
      v-if="selectedDestiny && !ended"
      size="l"
      @click="startExp"
    >
      Seleccionar hotel
    </BaseButton>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  data() {
    return {
      webOpts: ['trivago', 'tripadvisor', 'booking'],
      destiny: ['Benidorm', 'Granada', 'Málaga', 'Sevilla', 'Zaragoza'],
      selectedDestiny: undefined,
      cities: undefined,
      texts: undefined,
      cityName: undefined,
      ended: false,
    };
  },

  computed: {
    ...get('experimentModule', ['getWebOrder', 'getCities', 'getTexts']),
  },

  async created() {
    this.parseSecurity();
    this.cities = this.getCities();
    if (!this.cities) this.cities = await this.$store.dispatch('experimentModule/fetchCities');

    this.texts = this.getTexts();
    if (!this.texts) this.texts = await this.$store.dispatch('experimentModule/fetchTexts');

    if (localStorage.getItem('tripsimulatorEnd') !== null) {
      this.ended = true;
    }
  },

  methods: {
    parseSecurity() {
      console.log('borrando');
      localStorage.removeItem('tripsimulatorEnd');
      localStorage.removeItem('tripsimulator');
      localStorage.removeItem('tripsimulatorTimerTrivago');
      localStorage.removeItem('tripsimulatorTimerTripadvisor');
      localStorage.removeItem('tripsimulatorNext');
      localStorage.removeItem('tripsimulatorBack');
      localStorage.removeItem('tripsimulatorTimerBooking');
      localStorage.removeItem('adn');
    },

    startExp() {
      let shuffleArray = '';
      if (localStorage.getItem('adn') === null) {
        shuffleArray = this.generateAdn();
        this.saveLocalStorageAdn(shuffleArray);
      } else {
        shuffleArray = JSON.parse(localStorage.getItem('adn'));
      }
      this.$store.dispatch('experimentModule/setWebOrder', shuffleArray);

      if (localStorage.getItem('tripsimulator') === null) {
        const response = {
          location: this.selectedDestiny,
          adn: shuffleArray,
        };

        this.$store.dispatch('experimentModule/sendLocation', response)
          .then((callbackData) => {
            this.saveIdInLocalStorage(callbackData.data.id);
            this.$router.push({ name: shuffleArray[0] });
          })

          .catch((error) => {
            if (error.response.status === 400) {
              this.$router.push({ name: 'help' });
            }
          })

          .finally(() => {
          });
      } else {
        this.$router.push({ name: shuffleArray[0] });
      }
    },

    saveLocalStorageAdn(shuffleArray) {
      const parsed = JSON.stringify(shuffleArray);
      localStorage.setItem('adn', parsed);
    },

    saveIdInLocalStorage(id) {
      localStorage.setItem('tripsimulator', id);
    },

    setLocation(destiny) {
      this.selectedDestiny = destiny;
      const cityName = this.cities.data.filter(city => city.id === destiny);
      this.cityName = cityName[0].name;
    },

    generateAdn() {
      let currentIndex = this.webOpts.length; let
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        [this.webOpts[currentIndex], this.webOpts[randomIndex]] = [
          this.webOpts[randomIndex], this.webOpts[currentIndex]];
      }

      return this.webOpts;
    },
  },
};
</script>

<style lang="scss" scoped>
  .HomeView{
    margin: 3rem;
    padding: 2rem 4rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #03a9f42b;

    p{
      font-size:1.25rem;
    }

    li{
      font-size:1.5rem;
      margin-bottom: .5rem;
    }

    .bottom-block{
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
</style>
