var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ButtonArea" },
    [
      _vm.pageOrder !== 0
        ? _c(
            "BaseButton",
            {
              attrs: { variation: "back" },
              on: {
                click: function($event) {
                  return _vm.$emit("previousPageWeb")
                }
              }
            },
            [_vm._v(" Volver Atras ")]
          )
        : _vm._e(),
      _c(
        "BaseButton",
        {
          attrs: { variation: "select" },
          on: {
            click: function($event) {
              return _vm.$emit("selectHotel")
            }
          }
        },
        [_vm._v(" Seleccionar Hotel ")]
      ),
      _vm.pageOrder !== 2
        ? _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("nextWeb")
                }
              }
            },
            [_vm._v(" Seguir buscando ")]
          )
        : _vm._e(),
      _vm.pageOrder === 2
        ? _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("finish")
                }
              }
            },
            [_vm._v(" NO DESEO ESTE HOTEL ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }