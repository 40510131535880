var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.texts
    ? _c("div", { staticClass: "FinishView" }, [
        _c("p", [_vm._v(" " + _vm._s(_vm.texts.data[0].finishTitle) + " ")]),
        _c(
          "div",
          { staticClass: "bottom-block" },
          [
            _c("p", [
              _vm._v(" " + _vm._s(_vm.texts.data[0].finishPrimeText) + " ")
            ]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.texts.data[0].finishSecondText) + " ")
            ]),
            _c(
              "router-link",
              { attrs: { to: { name: "conditions" }, target: "_blank" } },
              [_vm._v(" Pulsa aquí para ver las condiciones ")]
            ),
            _c("div", { staticClass: "check-button" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checked,
                    expression: "checked"
                  }
                ],
                attrs: { id: "checkbox", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.checked)
                    ? _vm._i(_vm.checked, null) > -1
                    : _vm.checked
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.checked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.checked = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.checked = $$c
                    }
                  }
                }
              }),
              _c("label", { attrs: { for: "checkbox" } }, [
                _vm._v(
                  "Acepto las condiciones y quiero participar en el sorteo"
                )
              ])
            ]),
            _vm.visible
              ? _c("div", { staticClass: "input-block" }, [
                  _vm.checked
                    ? _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email"
                            }
                          ],
                          staticClass: "email-input",
                          attrs: {
                            id: "email",
                            type: "email",
                            placeholder: "Please enter your email here",
                            required: ""
                          },
                          domProps: { value: _vm.email },
                          on: {
                            blur: function($event) {
                              return _vm.validateEmail()
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            }
                          }
                        }),
                        _c(
                          "p",
                          [
                            !_vm.validate
                              ? _c("label", { attrs: { for: "email" } }, [
                                  _vm._v("Introduzca un email correcto ")
                                ])
                              : _vm._e(),
                            _vm.validate
                              ? _c(
                                  "BaseButton",
                                  {
                                    staticClass: "buttonActive",
                                    attrs: { size: "l" },
                                    on: {
                                      click: function($event) {
                                        return _vm.sendMail()
                                      }
                                    }
                                  },
                                  [_vm._v(" Enviar Email ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _c("div", [
                        _c("p", [
                          _vm._v(
                            " Debes aceptar las condiciones para poder continuar "
                          )
                        ])
                      ])
                ])
              : _c("div", [
                  _vm._v(" Se han registrado los datos correctamente ")
                ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }