<template class>
  <div class="ButtonArea">
    <BaseButton
      v-if="pageOrder !== 0"
      variation="back"
      @click="$emit('previousPageWeb')"
    >
      Volver Atras
    </BaseButton>

    <BaseButton
      @click="$emit('selectHotel')"
      variation="select"
    >
      Seleccionar Hotel
    </BaseButton>

    <BaseButton
      v-if="pageOrder !== 2"
      @click="$emit('nextWeb')"
    >
      Seguir buscando
    </BaseButton>

    <BaseButton
      v-if="pageOrder === 2"
      @click="$emit('finish')"
    >
      NO DESEO ESTE HOTEL
    </BaseButton>
  </div>
</template>

<script>
export default {
  props: {
    pageOrder: {
      type: Number,
      default: null,
    },
  },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .ButtonArea{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom:2rem;
    margin-top: 1rem;
  }
</style>
