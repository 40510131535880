<script>
export default {
  components: {
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      notifications: [],
    };
  },

  methods: {
    loadImageServer(image) {
      return `${process.env.VUE_APP_IMG_TRY_DIR}/${image}`;
    },

    getFinishWeb() {
      const webName = this.webOrder[this.webPosition];
      this.webPosition = null;
      this.selectHotel(webName);
    },

    stop() {
      clearInterval(this.timer);
    },

    addNextStepCount() {
      if (localStorage.getItem('tripsimulatorNext') === null) {
        localStorage.setItem('tripsimulatorNext', '1');
      } else {
        let countNext = parseInt(localStorage.getItem('tripsimulatorNext'), 10);
        countNext += 1;
        localStorage.setItem('tripsimulatorNext', countNext.toString());
      }
    },

    addBackStepCount() {
      if (localStorage.getItem('tripsimulatorBack') === null) {
        localStorage.setItem('tripsimulatorBack', '1');
      } else {
        let countBack = parseInt(localStorage.getItem('tripsimulatorBack'), 10);
        countBack += 1;
        localStorage.setItem('tripsimulatorBack', countBack.toString());
      }
    },

    selectHotel(web) {
      this.stop();
      let bookingTimer = 0;
      let trivagoTimer = 0;
      let tripadvisorTimer = 0;
      switch (web) {
        case 'booking':
          bookingTimer = this.elapsedTime;
          trivagoTimer = localStorage.getItem('tripsimulatorTimerTrivago') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerTrivago'), 10);
          tripadvisorTimer = localStorage.getItem('tripsimulatorTimerTripadvisor') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerTripadvisor'), 10);
          break;
        case 'trivago':
          trivagoTimer = this.elapsedTime;
          tripadvisorTimer = localStorage.getItem('tripsimulatorTimerTripadvisor') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerTripadvisor'), 10);
          bookingTimer = localStorage.getItem('tripsimulatorTimerBooking') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerBooking'), 10);
          break;
        case 'tripadvisor':
          tripadvisorTimer = this.elapsedTime;
          trivagoTimer = localStorage.getItem('tripsimulatorTimerTrivago') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerTrivago'), 10);
          bookingTimer = localStorage.getItem('tripsimulatorTimerBooking') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerBooking'), 10);
          break;
        default:
          tripadvisorTimer = localStorage.getItem('tripsimulatorTimerTripadvisor') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerTripadvisor'), 10);
          trivagoTimer = localStorage.getItem('tripsimulatorTimerTrivago') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerTrivago'), 10);
          bookingTimer = localStorage.getItem('tripsimulatorTimerBooking') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorTimerBooking'), 10);
      }
      const payload = {
        webposition: this.webPosition,
        id: localStorage.getItem('tripsimulator'),
        nextcount: localStorage.getItem('tripsimulatorNext') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorNext'), 10),
        backcount: localStorage.getItem('tripsimulatorBack') === null ? 0 : parseInt(localStorage.getItem('tripsimulatorBack'), 10),
        trivagotime: trivagoTimer,
        bookingtime: bookingTimer,
        tripadvisortime: tripadvisorTimer,
      };

      this.$store.dispatch('experimentModule/saveInfo', payload)
        .then((callbackData) => {
          localStorage.setItem('tripsimulatorEnd', 'true');
          console.log('web', this.webPosition);
          if (this.webPosition !== null) {
            console.log('entro');
            this.$router.push({ name: 'finish' });
          } else {
            this.$router.push({ name: 'goodbye' });
          }
        })

        .catch((error) => {
          if (error.response.status === 400) {
            this.$router.push({ name: 'help' });
          }
        })

        .finally(() => {
        });
    },

    calculatePrincipalRating() {
      const rooms = this.roomInfo.data.commentsArray;
      let sumatorio = 0;
      rooms.forEach((element) => {
        sumatorio += element[0].rating;
      });
      sumatorio /= 3;
      return sumatorio.toFixed(0);
    },
  },
};
</script>
